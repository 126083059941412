.choropleth-container{
    bottom: 40px;
    right: 295px;
    height: 155px;
    width: 110px;
    background-color: white;
    z-index: 2;
    position: absolute;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: -10px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-button{
    background-color: lightgray;
    border-radius: 10px;
    width: 90%;
    height: 29%;
    margin: 2px;
    border: 1px solid darkgray;
    transition: all 0.1s linear;
    font-size: 13px;
    font-weight: 400;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.24), 0 3px 5px 0 rgba(0,0,0,0.19);
}

.map-button:hover {
    filter: brightness(80%);
}

@media only screen and (max-width:700px){
    .choropleth-container{
        bottom: 0;
        right: 270px;
        height: 135px;
        width: 100px;
    }
    .map-button{
        font-size: 11px;
        font-weight: 500;
    }
}