.main-page{
    height: 100%;
}

.page-container{
    height:calc(100vh - 70px);
    width: 100vw;
    position:absolute;
    top: 70px;
    left: 0px;
    color: black;
    display: block;
    z-index: 3;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x:auto;
    overflow-wrap: break-word;
}


.about-text{
    height: 100%;
    width: 90vw;
    position:relative;
    top: 0px;
    margin: auto;
}


.centered{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-subtitle{
    padding-bottom: 3px;
    display: block;
    align-items: baseline;
    position: relative;
    text-decoration: none;
}

.about-subtitle::after{
    background: black;
    background: linear-gradient(to right, black 0%, black 40%, transparent 90%);  
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
}

p{
    line-height: 30px;
    inline-size: 90%;
}

.cite{
    text-indent: -36px;
    padding-left: 36px;
    margin-left: 30px;
    margin-right: 20px;
    justify-content: left;
    inline-size: 90%;
    line-height: 30px;
    margin-bottom: 20px;
}
