.small-container{
    bottom: 40px;
    right: 25px;
    height: 155px;
    width: 270px;
    background-color: white;
    z-index: 1;
    position: absolute;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 15px;
    opacity: 0;
    display: inline;
    transition: opacity 0.5s ease-in-out;
}

.legend-title{
    text-align: center;
    font-size: 17px;
    margin: 9px;
    height: 40px;
}

.llc-parcel{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #FF8D47;
    opacity: 0.9;
}

.nonllc-parcel{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #3CC8FF;
    opacity: 0.9;
}

.parcel-legend{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: center;
    background-color: white;
}

.parcel-contain{
    display: flex;
    flex-direction: row;
    margin: 11px;
}

@media only screen and (max-width:700px){
    .small-container{
        bottom: 0;
        right: 0;
        height: 135px;
    }
    .parcel-contain{
        margin: 0;
        margin-bottom: 15px;
        margin-left: 15px;
    }
}