.legend-container{
    bottom: 40px;
    right: 25px;
    height: 155px;
    width: 270px;
    background-color: white;
    z-index: 1;
    position: absolute;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 15px;
    display:inline;
    transition: opacity 0.5s ease-in-out;
}

.legend-bar{
    height: 20px;
    width: 100%;
    background: linear-gradient(to left, #fef0d9, #fdcc8a, #fc8d59, #e34a33, #b30000);
    border-radius: 5px;
    display: inline-flex;
    opacity: 0.8;
}

.labels{
    text-align: justify;
    margin:10px;
    font-size: 14px;
}

.legend-title{
    text-align: center;
    font-size: 17px;
    margin: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.block{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #828282;
    opacity: 0.8;
    margin-left: 10px;
}

.nodata{
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-top: 15px;
}

@media only screen and (max-width:700px){
    .legend-container{
        bottom: 0;
        right: 0;
        height: 135px;
        margin: auto;
    }
    .legend-title{
        font-size: 15px;
        margin: 10px;
    }
    .labels{
        margin:5px;
        font-size: 14px;
    }
    .block{
        margin-left: 5px;
    }
    .nodata{
        margin: 0px;
    }
}