.menu{
    width: 370px;
    height: 450px;
    background: white;
    position: fixed;
    top: -520px;
    right: 0px;
    border-bottom-left-radius: 15px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    opacity: 1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border: 1px solid lightgray;
}

ul{
    margin:0;
    padding:0;
    list-style: none;
    font-weight: 400;
    width: 60%;
}

.menu-block{
    margin-bottom: 30px;
    margin-left: 10px;
    margin-top: 10px;
}

.menu-option{
    text-decoration: none;
    color: black;
    font-size: 40px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}
.menu-option:hover{
        font-weight:600;
    }

@media only screen and (max-width:700px){
    .menu{
        width: 100%;
        height: 340px;
    }
    .menu-option{
        font-size: 25px;
        font-weight: 500;
    }
}