.topbar{
    top:0;
    left:0;
    width: 100vw;
    height: 70px;
    background: white;
    z-index: 5;
    position: absolute;
    justify-content: left;
    align-items: center;
    display: flex;
    border-bottom: 1px solid lightgrey;
}

.color2{
    top: 0;
    left: 0;
    width: 150px;
    height: 70px;
    background-color: white;
    display:inline;
}
.logo{
    width: 70px;
    height: 70px;
    display: inline;
    justify-content: center;
    align-items: left;
    margin-left: 10px;
}

.website-logo{
    height: 65px;
    margin-left: 10px;
    transition: 0.2s;
    margin-top: 4px;
}
.website-logo:hover{
    filter: brightness(80%);
    cursor: pointer;
}

.menu-btn{
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    transition: all 0.1s ease-in-out;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.menu-btn:hover{
    filter: brightness(80%);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}



/* hamburger and animation */
.menu-btn_burger{
    width: 25px;
    height: 4px;
    background: black;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}


.menu-btn_burger::before,
.menu-btn_burger::after{
    content: '';
    position: absolute;
    width: 25px;
    height: 4px;
    background: black;
    border-radius: 5px;
    transition: all .3s ease-in-out;
    box-shadow: grey;
}

.menu-btn_burger::before{
    transform: translateY(-8px)
}

.menu-btn_burger::after{
    transform: translateY(8px)
}

.menu-btn.open .menu-btn_burger{
    background: transparent;
}

.menu-btn.open .menu-btn_burger::before{
    transform: rotate(45deg) ;
    background: #AB4B52;
}

.menu-btn.open .menu-btn_burger::after{
    transform: rotate(-45deg) ;
    background: #AB4B52;
}