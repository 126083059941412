.city-text{
    height: 100%;
    width: 90vw;
    position:relative;
    top: 0px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.stat-chart{
    height: auto;
    width: auto;
    max-width: 1200px;
    min-width: 750px;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.chart-overflow{
    overflow-x: hidden;
}


@media only screen and (max-width:850px){
    .chart-overflow{
        overflow-x: scroll;
    }
}