.map-wrap{
  position: absolute;
  height: 100%;
  width: 100vw;
  margin: none;
  top: 0;
  left: 0;
  z-index: 0;
}
  
.map-container {
  height: calc(100vh - 70px);
  width: 100vw;
  position:relative;
  margin: none;
  top: 70px;
  left:0;
  z-index: 0;
}

.popup{
  height: 350px;
  width: 250px;
  font-size: 13px;
  font-weight: 300;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  scroll-behavior: smooth;
  padding-right: 5px;
  position: relative;
}

.mapboxgl-popup-content{
  width: 250px;
}

.popupTitle {
  background-color: #fc8d62;
  color: #fff;
  left: 0;
  right: 0;
  top: 0;
  font-size: 1.6em;
  font-weight: 500;
  padding: 0.4em;
  text-align: center;
  border-radius: 3px;
  position: sticky;
}

.sectionTitle {
  color: #5d5f64;
  background-color: white;
  left: 0;
  right: 0;
  font-size: 1.3em;
  font-weight: 600;
  padding: 0.5em;
  text-align: center;
}

.subTitle{
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
  font-weight: 300;
  font-size: small;
  padding-left: 5px; 
}

.data{
  padding-left: 5px;
}

.chart{
  height: 100px;
  z-index: 100;
}

.mapboxgl-popup-close-button{
  font-size: 29px;
  font-weight: 600;
  color: darkred;
  transition: 0.2s;
  background-color: white !important;
  right: -24px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.mapboxgl-popup-close-button:hover{
  color:red;
  filter: brightness(150%);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.mapbox-logo{ display: none; }
.mapboxgl-ctrl-logo { display: none !important; }
.mapbox-improve-map { display: none; }
.mapboxgl-ctrl-compass { display: none; }


#remover{
  background: white;
  width: 120px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  padding: 10px;
  top: 80px;
  right: 10px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid darkgray;
  transition: 0.2s;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.24), 0 3px 5px 0 rgba(0,0,0,0.19);
}
#remover:hover {
  filter: brightness(70%);
}

#toggleHelp{
  background: white;
  width: 180px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  z-index: 2;
  padding: 6px;
  top: 80px;
  left: 10px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid darkgray;
  transition: 0.2s;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.24), 0 3px 5px 0 rgba(0,0,0,0.19);}
#toggleHelp:hover {
  filter: brightness(70%);
}

@media only screen and (max-width:700px){
  #remover{
    font-size: 12px;
    width: 110px;
    padding: 8px;
    font-weight: 500;
    right:0;
    top:70px;
  }
  #toggleHelp{
    font-size: 12px;
    width: 150px;
    font-weight: 500;
    left:0;
    top:70px;
  }
  .popup{
    height: 250px;
    width: 250px;
  }
  .sectionTitle {
    font-size: 1em;
    font-weight: 600;
  }
  .mapboxgl-popup-close-button{
    font-size: 29px !important;
    font-weight: 600 !important;
    color: darkred;
    transition: 0.2s;
    background-color: white;
    right: -26px !important;
    width: min-content !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}