.sources-text{
    height: calc(100vh - 70px);
    width: 90vw;
    position:relative;
    top: 0px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.sources-list{
    padding-left: 0px;
    margin-left: 10px;
    justify-content: left;
    inline-size: 90%;
    line-height: 30px;
    margin-bottom: 5px;
}

.list-title{
    align-items: baseline;
}