.tip {
    background-color: #49454c;
    border: 3px solid #49454c;
    border-radius: 15px;
    color: white;
    padding: 5px;
    position: absolute;
    height: auto;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    opacity: inherit;
}

#choroplethTip{
    width:200px;
    top: 40%;
    right: 20%;
    opacity: inherit;
}

.tip.arrow-choropleth{
    margin-top: 20px;
}

.tip.arrow-choropleth:after{
    position: absolute;
    content: " ";
    left:-15px;
    top: 25px;
    border-right: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


#zoomTip{
    width: 200px;
    right: 70%;
    top: 27%;
    opacity: inherit;
}


#menuTip{
    width: 260px;
    right: 240px;
    top: 90px;
    border-top-right-radius: 0px;
}
.tip.arrow-menu{
    margin-top: 15px;
}

.tip.arrow-menu:after{
    position: absolute;
    content: " ";
    right:-13px;
    top: -11px;
    border-right: 29px solid #49454c;
    border-left: 27px solid transparent;
    border-bottom: 28px solid transparent;
}


#toggleTip{
    width: 140px;
    right: 10px;
    top: 130px;
    opacity: inherit;
}
.tip.arrow-toggle{
    margin-top: 10px;
}

.tip.arrow-toggle:after{
    position: absolute;
    content: " ";
    left: 100px;
    top: -15px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #49454c;
}


#legendTip{
    width: 350px;
    right: 420px;
    bottom: 80px ;
    opacity: inherit;
}

.tip.arrow-legend{
    margin-right: 20px;
}

.tip.arrow-legend:after{
    position: absolute;
    content: " ";
    right:-15px;
    top: 25px;
    border-left: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


.modalContent {
    opacity: 0;
    z-index: 40;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width:700px){
    .tip {
        font-weight: 300;
        font-size: 13px;
    }
    #choroplethTip{
        width:130px;
        top: 50%;
        right: 5%;
        opacity: inherit;
    }
    #toggleTip{
        width: 120px;
        right: 5px;
        top: 115px;
        border-top-right-radius: 7px;
    }
    .tip.arrow-toggle{
        margin-top: 10px;
    }
    #zoomTip{
        width: 130px;
        right: 60%;
        top: 37%;
        opacity: inherit;
    }
    #menuTip{
        width: 160px;
        right: 180px;
        top: 110px;
        border-top-right-radius: 0px;
    }
    #legendTip{
        width: 250px;
        right: 20%;
        bottom: 140px ;
        opacity: inherit;
    }

}