.bottomBar{
    position: absolute;
    z-index: 10;
    width: 430px;
    height: 20px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    font-size: 11px;
    font-weight: 500 !important;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 5px;
    opacity: 0.6;
}

.bottomLink a:link{
    color: #000;
}
.bottomLink a:visited{
    color: #000;
}
.bottomLink a:hover{
    color: #8da0cb;
}

.rsfLink a:link{
    color: #000;
}
.rsfLink a:visited{
    color: #000;

}
.rsfLink a:hover{
    color: #8da0cb;
}

.cptrLink a:link{
    color: #000;
}
.cptrLink a:visited{
    color: #000;
}
.cptrLink a:hover{
    color: #8da0cb;
}

.userLink a:hover{
    color: #8da0cb;
}

.sourcesLink a:link{
    color: #000;
}
.sourcesLink a:visited{
    color: #000;
}
.sourcesLink a:hover{
    color: #8da0cb;
}

@media only screen and (max-width:700px){
    .bottomBar{
        display: none;
    }
}
